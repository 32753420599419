@import '../../styles/variables';
@import '../../styles/mixin';

.page_not_found {
  user-select: none;

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 64px;
    gap: 1rem;

    @include media(md) {
      margin-bottom: 96px;
    }

    &__description {
      font-size: $text-sm;
      line-height: 1.733;
      text-align: center;

      @include media(md) {
        font-size: $text-base;
      }
    }

    .heading {
      &__image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
        margin-bottom: 2rem;

        img {
          flex-grow: 1;
          height: auto;
          width: auto;
        }
      }

      &__image_default {
        padding: 36px;

        @include media(sm) {
          padding: 68px;
        }

        @include media(sm) {
          padding: 68px;
        }

        svg {
          width: 100%;
          height: auto;
          max-width: 180px;

          @include media(xl) {
            max-width: 260px;
          }

          @include media(3xl) {
            max-width: 280px;
          }
        }
      }

      &__description {
        font-size: $text-xl;
        font-weight: 500;

        @include media(sm) {
          font-size: $text-3xl;
        }
      }
    }
  }

  &__cta_section {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    @media screen and (max-width: 420px) {
      flex-direction: column;
    }

    @include media(md){
      gap: 24px;
    }

    .go_back_btn {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    button {
      min-width: 180px;
      width: fit-content !important;

      @include media(md){
        min-width: 236px;
      }
    }
  }
}
