@import '../../styles/variables';

.summary_skeleton__container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .summary_skeleton {
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    width: 100%;

    &__item {
      height: 20px;
      border-radius: 1rem;
      width: 100px;
      background-color: $cart-skeleton-color;
    }

    &__item_large {
      height: 20px;
      border-radius: 1rem;
      width: 150px;
      background-color: $cart-skeleton-color;
    }
  }
}
