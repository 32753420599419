@import '../../styles/variables';
@import '../../styles/mixin';

:global(.dark-theme) {
  .counter {
    .counter__value {
      border-color: $detail-counter-border;
      color: $detail-counter-value-dark;
    }
  }
}

.counter__value {
  display: flex;
  align-items: center;
  border: 0.64px solid $detail-counter-border;
  background-color: $counter-bacground;
  border-radius: $counter-border-radius;
  width: max-content;
  height: 100%;
  color: $counter-text;
  font-weight: 700;
  font-size: $text-sm;
  line-height: 1rem;

  .counter__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    padding: 8px;
    height: 32px;
    line-height: 20.44px;
    background-color: $counter-bacground-count;
    border-right: 0.64px solid $counter-divider;
    border-left: 0.64px solid $counter-divider;
  }

  .counter__operation {
    height: 32px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    color: $detail-counter-operation;
    background-color: $counter-bacground;
    border-radius: $counter-border-radius;

    svg {
      width: 16px;
      height: 16px;
      margin-top: 0;
      transform: scale(1);

      * {
        stroke: $counter-operation-color;
      }
    }

    &:hover,
    &:focus-within {
      svg {
        * {
          stroke: $counter-operation-color-hover;
        }
      }
    }
  }
}

.counter {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &_shopping,
    &_detail {
      display: flex;
      flex-wrap: nowrap;
      column-gap: 12px;

      .counter__variant-select {
        background-color: $counter-bacground !important;
        border-radius: $counter-border-radius !important;
        position: unset;

        button {
          width: 78px;
          flex-grow: 1;
          padding: 8px;
          color: $counter-text;
          font-size: $text-sm;
          font-weight: 700;
          line-height: 14px !important;
          height: 34px;
          border: 0.64px solid $counter-border !important;
          background-color: $counter-bacground;
          border-radius: inherit;

          i {
            font-weight: 700;
            font-size: $text-base;
          }
        }

        .variant-select__menu {
          width: fit-content;
          min-width: 60.44px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    &_detail {
      display: flex;
      //flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      height: 56px;

      .counter__value {
        height: 40px;
        padding: 8px;

        .counter__operation {
          padding: 0 8px 0 0;

          &_rigth {
            padding: 0 0 0 8px !important;
          }
        }
      }
    }
  }

  .on_cart {
    background: $primary !important;
    transition: none;

    &:hover {
      background: lighten($color: $primary, $amount: 10%) !important;
    }

    i {
      color: $white;
      font-size: 18px;
    }
  }

  .counter__btn-responsibe,
  .counter__btn-update-cart {
    width: 100%;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    > div {
      display: grid;
      width: fit-content;
      margin: 0 auto;
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; 

      .counter__btn-icon {
        flex-shrink: 0;
        margin-right: 6px;
        svg {
          width: 20px;
          height: 20px;
          * {
            stroke-width: 2.3 !important;
          }
        }
      }

      .counter__text {
       // line-height: 1;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
      }
    }

    @media screen and (max-width: 420px) {
      padding: 11px 16px !important;
      font-size: $text-sm;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 11px;
      font-size: $text-sm;
    }
  }

  .counter__btn-update-cart {
    width: -webkit-fill-available;
  }

  .counter__btn-check {
    width: -webkit-fill-available;

    @media screen and (max-width: 520px) {
      font-size: $text-sm;
      padding: 11px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: $text-sm;
    }

    @include media(lg) {
      font-size: $text-sm;
      padding: 11px 24px;
    }

    @include media(xl) {
      font-size: $text-base;
    }
  }

  .counter__btn-update-cart_disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.counter__warning {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    color: $badge-text-warning;
    border-radius: calc($border-radius-btn - 2px);
    border: 0;
    min-width: 82.29px;
    padding: 4px 8px;
    line-height: 1rem;
    height: 22px !important;
    background: $badge-warning;
    font-size: $text-sm;
    font-weight: 500;

    &_btn {
      padding: 4px;
      height: 24px !important;

      @include media(sm){
        font-size: $text-base;
      }
    }
  }
