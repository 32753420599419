@import '../../styles/variables';
@import '../../styles/mixin';

.figure_placeholder {
  position: relative;
  align-self: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $gray-50;
  border-radius: 8px;

  img {
    height: auto;
    border-radius: 8px;
  }

  svg {
    position: absolute;
    height: 18px;
    width: 18px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.spacing_bottom {
  margin-bottom: $margin-section-mobile;

  @include media(lg) {
    margin-bottom: $margin-section;
  }
}

.padding_background {
  padding-top: 36px;
  padding-bottom: 36px;

  @include media(md){
    padding-top: 52px;
    padding-bottom: 52px;
  }
}

.categories_carousel {
  @media screen and (max-width: 480px) {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .collections__see-all {
    font-weight: 500;
    color: $see-all-link;
    font-size: $text-sm;
    flex-shrink: 0;

    @include media(md) {
      font-size: $text-base;
    }

    span {
      margin-left: 5px;
      color: $see-all-counter !important;
    }
  }

  .slide__main {
    display: flex !important;
    flex-direction: column;
    justify-content: stretch;
    row-gap: 12px;
    text-align: center;
    width: 100%;
  }

  .category_image {
    order: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    min-width: 60px;
    border-radius: $border-radius-md;

    @include media(sm){
      min-width: 100px;
    }

    img {
      width: auto;
    }

    &_placeholder {
      @extend .figure_placeholder;
      height: auto;
    }
  }

  h3 {
    order: 1;
    flex-grow: 0;
    font-size: $text-sm !important;
    font-weight: 500;
    line-height: 24px;

    @include media(md) {
      font-size: $text-base !important;
    }
  }

  &__placeholder {
    &_category {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      row-gap: 8px;
      min-width: 100px;
      margin-right: 12px;

      @include media(lg) {
        min-width: 140px;
      }
    }

    &_image,
    &_title {
      background-color: $carousel-categories-placeholder !important;
      width: 100%;
    }

    &_image {
      border-radius: $border-radius;
      aspect-ratio: 1/1;
    }

    &_title {
      height: 14px;
      width: 70%;
    }
  }
}

.categories_grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 44px;
  text-align: center;

  &__container {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;

    @media screen and (min-width: 450px) {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      row-gap: 40px;
    }

    @include media(xl) {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      gap: 40px;
    }

    &__categories {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      row-gap: 10px;

      .image {
        order: 1;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 100%;
        margin: 0 !important;

        img {
          height: auto;
        }

        &_placeholder {
          @extend .figure_placeholder;
          padding-top: 100%;
        }
      }

      a {
        order: 3;
        background: $btn-primary;
        border-radius: $border-radius-btn;
        color: $label-btn-primary;
        font-size: $text-sm;
        font-weight: 500;
        margin: auto;
        margin-top: 10px;
        padding: 7px 30px;
        transition: $transition-all;
        text-align: center;
        width: 150px;
      }

      p {
        order: 2;
      }
    }
  }
}
