@import '../../styles/variables';
@import '../../styles/mixin';

.layout__flex_colum {
  display: flex;
  flex-direction: column;
}

.cart_nav {
  $space-x: 2.5rem;
  $form-width: 26.125rem;

  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  left: 0;
  pointer-events: none;
  user-select: none;

  .cart_nav__container {
    @extend .layout__flex_colum;
    height: 100%;
    width: calc(100% - 2rem);
    max-width: $form-width;
    position: absolute;
    top: 0;
    right: -$form-width;
    box-shadow: $box-shadow;
    transition: $transition-all;
    background: $background-shoping-cart;
    backdrop-filter: $shoping-cart-backdrop-filter;
    z-index: 1;
    pointer-events: initial;
    will-change: transform;

    @media screen and (max-width: 420px) {
      width: calc(100% - 1rem);
    }
  }

  .cart_nav__body {
    @extend .layout__flex_colum;
    padding: 1.5rem 1.5rem 0;
    width: 100%;
    min-height: 10rem;
    flex-grow: 1;

    @media screen and (max-width: 420px) {
      padding: 1rem 1rem 0;
    }
  }

  .cart__header {
    @extend .layout__flex_colum;
    row-gap: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 0.5px solid $cart-divider;

    .cart__shopping {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cart__shopping_link {
        font-weight: 500;
        font-size: $text-2xl;
        line-height: 1.95rem;
        letter-spacing: 0.015px;
        color: $cart-title-color;
      }

      .close {
        margin-left: auto;
      }

      i {
        color: $cart-btn-close;
      }
    }

    .cart__shopping_at {
      display: flex;
      align-items: center;
      color: $cart-method-select;
      font-size: $text-sm;
      line-height: 1rem;
      width: 100%;
      gap: 0.625rem;

      .shopping__title {
        margin-bottom: 2px;
        font-weight: 500;
        flex-grow: 1;
        width: 100%;
      }

      .shopping__store {
        font-weight: 400;
        color: $cart-adderss;
        line-height: 1.25rem;
      }

      figure {
        position: relative;
        flex-shrink: 0;

        svg {
          width: 1.5rem;
          height: 1.5rem;

          * {
            stroke: $cart-market;
          }
        }
      }

      .select_store_button {
        color: $primary;
        text-decoration: underline;
        width: fit-content;
        padding: 0.5rem 0;
        cursor: pointer;
      }
    }
  }

  .cart__empty {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    min-height: 0;
    color: $cart-empty-text;
    overflow: hidden;

    .empty_cart_icon {
      color: $cart-empty-icon;
      margin-bottom: 1rem;

      svg {
        width: $cart-empty-bag-size;

        path[class='path-base'] {
          fill: $cart-empty-bag-base-color;
        }

        path[class='path-trace'] {
          fill: $cart-empty-bag-trace-base-color;
        }

        path[class='path-side-bag'] {
          fill: $cart-empty-bag-side-color;
        }

        path[class='path-lateral-stroke'] {
          fill: $cart-empty-bag-lateral-stroke;
        }

        path[class='path-front-bag'] {
          fill: $cart-empty-bag-front-bag;
        }

        path[class='path-signal'] {
          fill: $cart-empty-bag-signal;
        }

        path[class='path-shadow'] {
          fill: $cart-empty-bag-shadow;
        }

        path[class='path-counter-badge'] {
          fill: $cart-empty-bag-counter-badge;
        }

        path[class='path-text-counter-badge'] {
          fill: $cart-empty-bag-text-counter-badge;
        }

        path[class='path-bag-strap'] {
          fill: $cart-empty-bag-strap;
        }
      }
    }
  }

  .heading.heading_level-3 {
    font-size: $text-base;
    margin-bottom: 1.375rem;
    font-weight: 600;
  }

  .cart__body_container {
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    height: 100%;
    min-height: 10rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .cart__product {
      display: flex;
      flex-direction: column;
      width: 100%;

      &_active_bogo {
        border-radius: 12px;
        border: 1px solid $cart-active-bogo-color;
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }

    .cart__product_body {
      display: flex;
      width: 100%;
      column-gap: 1rem;
      padding: 1.5rem 0;
      border-bottom: 0.5px solid $cart-divider;

      @media screen and (max-width: 420px) {
        column-gap: 0.75rem;
      }

      &_active_bogo {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .cart_item_bogo {
      cursor: pointer !important;
      align-items: center;
      display: flex;
      border-radius: 0px 0px 12px 12px;
      width: 100%;
      column-gap: 6px;
      width: -webkit-fill-available;
      padding: 12px;
      background-color: $active-bogo;
      border: $active-bogo-border;
      color: $active-bogo-text;
      background-image: none;

      @include media(sm) {
        column-gap: 12px;
      }

      &_message {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &_close {
        color: $active-bogo-text;
        cursor: pointer !important;
      }

      span,
      a {
        color: $active-bogo-text;
        font-size: 10px;
        line-height: 14px;
      }

      a {
        cursor: pointer !important;
        border-bottom: 1px solid $active-bogo-text;
        width: fit-content;
      }

      span {
        font-weight: 400;
      }

      figure {
        position: relative;
        flex-shrink: 0;
      }

      svg {
        transform: scale(0.9);

        @include media(sm) {
          transform: scale(1);
        }

        * {
          stroke-width: 2.5;
          stroke: $active-bogo-text;
        }
      }
    }

    .cart__item_image {
      @extend .layout__flex_colum;
      position: relative;
      width: 100%;
      max-width: 4.5rem;
      background-color: $cart-body-bacground-image;
      border-radius: $cart-body-border-radius-image;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 0.25rem;

      figure {
        position: relative;
        background-color: inherit;
        overflow: hidden;
        width: 3.5rem;
        height: 3.5rem;

        @include media(sm) {
          width: 4.5rem;
          height: 4.5rem;
        }
      }

      img {
        border-radius: $cart-body-border-radius-image;
        height: auto;
      }

      .product__in_stock {
        color: $gray-300;
        font-size: $text-xs;

        @media screen and (max-width: 400px) {
          font-size: 0.5rem;
        }
      }
    }

    .cart__detail_item {
      @extend .layout__flex_colum;
      row-gap: 0.75rem;
      width: 100%;

      @media screen and (max-width: 420px) {
        column-gap: 0.75rem;
      }

      .cart__detail_brand {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 0.5rem;
        row-gap: 0.25rem;

        p {
          font-size: 0.75rem;
          line-height: 1rem;
          text-transform: uppercase;
          color: $cart-body-brand-color;
        }

        .flower__type {
          height: 1rem;
          padding: 2px 4px;
          width: fit-content;
          font-weight: 500;
          font-size: 0.625rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius-badge;
          text-align: center;
          cursor: default;

          strong {
            text-transform: uppercase;
          }
        }

        $flower_types: (
          'default'$flower-type-default-bg-color $flower-type-default-color,
          'sativa'$flower-type-sativa-bg-color $flower-type-sativa-color,
          'indica'$flower-type-indica-bg-color $flower-type-indica-color,
          'hybrid'$flower-type-hybrid-bg-color $flower-type-hybrid-color,
          'vape'$flower-type-vape-bg-color $flower-type-vape-color,
          'extract'$flower-type-extract-bg-color $flower-type-extract-color,
          'cbd'$flower-type-cbd-bg-color $flower-type-cbd-color
        );

      @each $name, $bg, $color in $flower_types {
        .type__#{$name} {
          background-color: $bg !important;
          color: $color !important;
        }
      }
    }
  }

  .cart__product_badges {
    display: flex !important;
    gap: 6px;
    align-items: center;
    flex-grow: 1;

    span {
      display: inline-block;
      background: $badge-warning;
      color: $cart-badge-on-sale-text-color;
      border-radius: $border-radius-badge;
      height: 20px;
      padding: 3px 8px;
      font-size: $text-small;
      font-weight: 500;
      line-height: 14px;

      max-width: 140px;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      i {
        margin-right: 2px;
      }
    }

    .product__sale {
      background: $badge;
      border: 0.5px solid $badge-border-color;
      color: $cart-badge-on-sale-text-color;
    }

    .product__stock {
      background: $badge-warning;
      border: 0.5px solid $badge-warning-border-color;
      color: $badge-text-warning;
    }

    .product__bundle {
      background-color: $badge-specials;
      color: $cart-badge-specials-text-color !important;
      font-size: $text-small;
      font-weight: 400;
      text-transform: inherit;
    }
  }

  .cart__info_container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 1rem;

    @media screen and (max-width: 420px) {
      column-gap: 0.75rem;
    }

    .heading_level_4 {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: $text-sm;
      font-weight: 500;
      line-height: 1.375rem;
      color: $cart-body-title-color;
      margin-bottom: 0 !important;
      max-height: 3rem;

      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      @supports (-webkit-line-clamp: 2) {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
      }

      @include media(sm) {
        font-size: $text-base;
        font-weight: 500;
        line-height: 1.5rem;
      }
    }

    .price__container {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      right: 2px;

      /*   .divider {
            border-right: 0.5px solid $gray-200;
            width: 1px;
            height: 100%;
            padding-right: 16px;
            flex-grow: 1;

            @media screen and (max-width: 420px) {
              flex-grow: 0;
            }
          }
  */
      p {
        text-transform: uppercase;
        text-align: right;
        flex-grow: 1;

        del {
          font-weight: 400;
          font-size: $text-xs;
          line-height: 1rem;
          text-decoration-line: line-through;
          color: $discount-price;
        }

        ins {
          font-size: $text-base;
          display: block;
          font-weight: 700;
          line-height: 18px;
          color: $cart-body-title-color;
        }

        span {
          line-height: 14px;
          font-size: 10px;
        }
      }
    }
  }

  .brand__unit {
    display: flex;
    flex-wrap: wrap;
    color: $cart-body-text-color-secondary;
    line-height: 18px;
    font-weight: 400;
    font-size: $text-sm;
    margin-bottom: 8px;
    align-items: initial;

    span {
      text-transform: uppercase;
    }
  }

  .brand {
    font-weight: 400;
    font-size: $text-sm;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $cart-body-text-color-secondary;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  h4 {
    font-weight: 500;
    font-size: $text-base;
    line-height: 24px;
    color: $cart-body-text-color;
    margin-bottom: 12px;
  }

  .cart__product-variants {
    margin-top: 4px;
  }

  strong {
    font-weight: 500;

    .icon {
      color: $success;
      font-size: $text-lg;
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    row-gap: 16px;
  }
}

.cart__footer {
  background-color: $cart-footer-background;
  box-shadow: $cart-footer-shadow;
  flex-grow: 0;

  .footer__current_shopping {
    border-top: 0.5px solid $cart-footer-divider;
    padding: 1.5rem 0;
    margin: 0 1.5rem;

    p {
      font-weight: 700;
      font-size: $text-base;
      line-height: 1.875rem;
      color: $cart-footer-text;
      display: flex;
      justify-content: space-between;

      .subtotal {
        font-weight: 700;
        font-size: $text-xl;
        color: $cart-footer-price;
      }
    }

    .footer__grams_container {
      display: flex;
      justify-content: space-between;
      line-height: 1.5rem;
      height: 1.875rem;
      font-weight: 400;

      .heading {
        display: flex;
        align-items: center;
        color: $cart-footer-text;

        .footer__help {
          margin-left: 0.5rem;
          position: relative;

          button {
            outline: 0;
            text-align: center;
            margin-top: -2px;
            height: 1.25rem;

            i {
              font-size: $text-xl;
            }
          }

          &_body {
            background: $white;
            border-radius: $border-radius;
            box-shadow: -10px 0px 12px rgba(186, 186, 186, 0.3);
            border: 0.5px solid $gray-100;
            padding: 1rem;
            font-size: $text-sm;
            line-height: 1.5rem;
            color: $cart-help-text;
            position: absolute;
            bottom: 90%;
            left: 50%;
            transform: translate(-48%, -10px);
            z-index: 30;
            width: 15rem;

            @include media(sm) {
              width: 335px;
              //transform: translate(-48%, -10px);
            }
          }
        }
      }

      .grams {
        font-size: $text-base;
        line-height: 1.875rem;
        color: $cart-footer-price;
      }

      &_subtotal {
        span {
          font-weight: 700;
          font-size: $text-base;
          line-height: 1.875rem;
          color: $cart-footer-text;
        }
      }
    }

    .footer__discount_section {
      line-height: 1.5rem;
      min-height: 1.875rem;
      font-weight: 400;

      &__discount_included {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
          display: flex;
          align-items: center;
          color: $cart-footer-text;
        }

        .grams {
          display: block;
          font-size: $text-base;
          line-height: 1.875rem;
          color: $cart-footer-price;
        }
      }

      .footer__discounts_btn {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .heading_section {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          i {
            color: $cart-footer-text;
          }

          .heading {
            display: flex;
            align-items: center;
            color: $cart-footer-text;
          }
        }

        .grams {
          display: block;
          font-size: $text-base;
          line-height: 1.875rem;
          color: $cart-footer-price;
        }
      }

      .footer__discounts_container {
        display: flex;
        flex-direction: column;
        color: $cart-footer-text;

        .discount_item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .discount_item_amount {
            font-size: $text-base;
            line-height: 1.875rem;
            color: $cart-footer-price;
          }
        }
      }
    }

    .proceed {
      width: 100%;
      margin-top: 1.5rem;

      .proceed_label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        i {
          color: $label-btn-primary;
        }
      }
    }

    .tax_info {
      font-size: $text-sm;
      font-weight: 500;
      line-height: 1.875rem;
      color: $cart-footer-text;
    }
  }

  .peak {
    background: $white;
    bottom: -7px;
    border-right: 0.5px solid $gray-100;
    border-bottom: 0.5px solid $gray-100;
    // box-shadow: 10px 0px -10px rgba(186, 186, 186, 0.3);
    height: 0.75rem;
    position: absolute;
    transform: rotateZ(45deg);
    right: 50%;
    width: 0.75rem;
  }

  .related {
    margin-bottom: 1rem;

    .slider__arrow {
      display: none;
    }

    .slider__items {
      scroll-snap-type: none;
    }
  }
}

.cart_nav__overlap {
  background: $overlay-bg;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: $transition-all;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

&.cart_nav__open {
  .cart_nav__container {
    right: 0;
  }

  .cart_nav__overlap {
    visibility: visible;
    opacity: 1;
    pointer-events: initial;
  }
}
}