@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
  width: 100%;

  @include media(sm) {
    padding: 0 40px;
  }

  @each $size, $i in $breakpoints {
    @include media($size) {
      max-width: $i;
    }
  }
}
