@import '../../styles/variables';
@import '../../styles/mixin';

.button {
  border-radius: $border-radius-btn;
  font-weight: 500;
  padding: 11px 30px;
  //transition: $transition-all;
  white-space: nowrap;
  font-size: $text-base;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 56px;

  .button__content {
    width: 100%;
    //transition: $transition-all;
    background-color: transparent;
    border: none !important;
  }

  &.button_color-primary {
    color: $label-btn-primary;
    background: $btn-primary;
    text-transform: uppercase;
    border: $border-width-btn solid $border-btn-primary;

    svg {
      * {
        stroke: $label-btn-primary;
      }
    }

    &:not(:disabled):hover {
      color: $label-btn-primary-hover;
      background: $btn-primary-hover;
      border: $border-width-btn solid $border-btn-primary-hover;

      svg {
        * {
          stroke: $label-btn-primary-hover;
        }
      }
    }

    &:not(:disabled):focus {
      color: $label-btn-primary-pressed;
      background: $btn-primary-pressed;
      border: $border-width-btn solid $border-btn-primary-pressed;

      svg {
        * {
          stroke: $label-btn-primary-pressed;
        }
      }
    }

    &:disabled {
      color: $label-btn-primary-disabled;
      background: $btn-primary-disabled;
      border: $border-width-btn solid $border-btn-primary-disabled;

      svg {
        * {
          stroke: $label-btn-primary-disabled;
        }
      }
    }
  }

  &.button_color-secondary {
    color: $label-btn-secondary;
    border: $border-width-btn solid $btn-secondary;
    background: $background-btn-secondary;
    text-transform: uppercase;
    width: 100%;

    svg {
      * {
        stroke: $label-btn-secondary;
      }
    }

    &:not(:disabled):hover {
      border: $border-width-btn solid $btn-secondary-hover;
      color: $label-btn-secondary-hover;
      background: $background-btn-secondary-hover;

      svg {
        * {
          stroke: $label-btn-secondary-hover;
        }
      }
    }

    &:not(:disabled):focus {
      background: $background-btn-secondary-pressed;
      color: $label-btn-secondary-pressed;
      border: $border-width-btn solid $btn-secondary-pressed;

      svg {
        * {
          stroke: $label-btn-secondary-pressed;
        }
      }
    }

    &:disabled {
      background: $btn-secondary-disabled;
      color: $label-btn-secondary-disabled;
      border: $border-width-btn solid $background-btn-secondary-disabled;

      svg {
        * {
          stroke: $label-btn-secondary-disabled;
        }
      }
    }
  }

  &.button_color-link {
    color: $label-btn-link;

    svg {
      * {
        stroke: $label-btn-link;
      }
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: $label-btn-link-hove;
      
      svg {
        * {
          stroke: $label-btn-link-hove;
        }
      }
    }
  }

  &.button_icon {
    width: 47px;
    height: 47px;
    padding: 0;
    border: 0;
  }
  &.footer {
    background: $btn-background-footer;
    border-radius: $border-radius-btn;
    width: 100%;
    padding: 11px 16px;
    text-align: center;

    .button__content {
      font-weight: 500;
      font-size: $text-sm;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $text-btn-footer;

      i {
        color: $btn-background-footer;
      }

      .spinner {
        margin-right: 13px;
      }
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background: $btn-background-footer-hover;

      .button__content {
        color: $text-btn-footer-hover;
      }
    }
  }

  .icon {
    font-size: $text-xl;
  }

  &:not(.button_icon) .icon {
    margin-right: 4px;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:disabled {
    //opacity: 1;
    cursor: not-allowed;
  }

  &.button_sm {
    font-size: $text-base;
    padding: 6.5px 14px;
    height: 48px;

    &.button_icon {
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  .button__content {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 !important;
    background-color: inherit !important;
  }

  .spinner {
    margin-right: 7px;
  }
}
