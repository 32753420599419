@import '../../styles/variables';
@import '../../styles/mixin';

$bearpoints-2: (
  sm: 512px,
  md: 640px,
  lg: 768px,
  xl: 1024px,
);

.contactus {
  position: relative;
  overflow: hidden;

  .contactus_container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }

  .contactus_container__maptop {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .contactus_container__mapleft {
    
    @media screen and (max-width: 1024px) {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      max-width: unset;
    }    
  }

  .contactus_container__iconrow {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media(sm) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  .contactus__header {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 20px;
    max-width: 428px;

    @include media(md) {
      width: 65%;
      max-width: 756px;
    }

    &_sidebyside {
      width: 100%;

      @include media(sm) {
        max-width: 756px !important;
      }
    }

    &_maptop {
      @media screen and (max-width: 460px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &_mapleft {
      margin-bottom: 26px;
      @media screen and (max-width: 460px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include media(md) {
        width: 100%;
      }

      @include media(lg){
         align-items: flex-start;
         text-align: left;
      }
    }
  }

  .contactus__body {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 32px;

    &_sidebyside {
      max-width: 428px;
      padding-bottom: 32px;
      margin: 0 auto;

      @include media(sm){
        max-width: 756px;
        display: grid;
        grid-template-columns: 1fr minmax(0, 205px);
        column-gap: 32px;
      }

      @include media(2xl){
        column-gap: 58px;
      }
    }

    &_centered {
      margin: 0 auto;
      max-width: 428px;

      @include media(md) {
        width: 65%;
        max-width: 756px;
      }
    }

    &_map_top {
      @media screen and (max-width: 460px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &_map_left {
      
      @include media(lg){
        display: grid;        
        grid-template-columns: 1fr minmax(0, 480px);
      }

      @include media(xl){
        display: grid;
        grid-template-columns: 1fr minmax(0, 600px);
      }

      @include media(2xl){
        display: grid;
        grid-template-columns: 1fr minmax(0, 660px);
      }

      @include media(3xl){
        display: grid;
        grid-template-columns: 1fr minmax(0, 940px);
      }
    }

    &_map_default {
      
      @include media(lg){
        display: grid;        
        grid-template-columns: 1fr minmax(0, 460px);
      }

      @include media(xl){
        display: grid;
        grid-template-columns: 1fr minmax(0, 600px);
      }

      @include media(2xl){
        display: grid;
        grid-template-columns: 1fr minmax(0, 660px);
      }

      @include media(3xl){
        display: grid;
        grid-template-columns: 1fr minmax(0, 940px);
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 428px;
    width: 100%;
    order: 1;
    row-gap: 14px;
    user-select: none;

    @include media(md){
      max-width: 100%;
    }

    &__names {
      display: flex;
      flex-direction: column;
      gap: 14px;

      &--mapTop {
        @include media(md) {
          flex-direction: row;
        }
      }     
    }

    &.form__centered {
      padding-left: 0;

      .heading {
        text-align: center !important;
        margin-bottom: 16px !important;
      }

      > p {
        margin: 0 auto 28px;
        max-width: 700px;
        text-align: center;
      }
    }

    &.form__sideBySide {
      padding-left: 0;
    }

    &.maptop__form {
      padding-bottom: 32px;
    }

    &.mapleft__form {
      @media screen and (max-width: 460px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include media(md){
        max-width: 522px;
        grid-column: 2 / 3;
        position: relative
      }

      @include media(lg){
        align-self: center;
        max-width: 365px;
        //margin: 0;
      }

      @include media(xl){
        max-width: 496px;
      }

      @include media(3xl){
        max-width: 68%;
      }
    }

    &.mapdefault__form {
      @include media(md){
        max-width: 522px;
        grid-column: 2 / 3;
        position: relative
      }

      @include media(lg){
        padding-block: 40px;
        align-self: center;
        max-width: 365px;
      }

      @include media(xl){
        max-width: 496px;
      }

      @include media(3xl){
        max-width: 68%;
      }
    }


    &__action {
      width: 100%;

      @include media(md){
        max-width: 365px;
        margin: 0 auto;
      }

      &.maptop__form__action {
        align-items: center;
        align-self: center;
        display: flex;
        justify-content: center;
        width: 100%;  
      }

      &.sidebyside__form__action {
        margin: 0;
      }

      &.default__form__action {
        margin: 26px 0 0;
        
        @include media(md){
          max-width: 100%;
        }

        @include media(xl){
          max-width: 365px;
        }
      }
    }
  }

  &__map {
    position: relative;
    height: 560px;

    @include media(sm){
      height: 440px;
    }

    &.mapleft__map {

      @include media(lg){
        grid-column: 1 / 2;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;;
        width: 100vw;
        width: 51%;
        height: 100%;
      }

      @include media(xl){  
        width: 51.5%;     
      }

      @include media(2xl){
        width: 54.53% !important;
      }

      @include media(3xl){
        width: 52.8% !important;
      }
    }

    &.mapdefault__map {

      @include media(lg){
        grid-column: 1 / 2;
        width: 100%;
        height: 100%;
      }
    }

    &__address {
      background: $white;
      border-radius: $border-radius;
      font-style: normal !important;
      box-shadow: $box-shadow;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 100%;
      max-width: 282px;
      padding: 30px;
      position: absolute;
      top: 24px;
      left: 12px;

      &.mapdefault__map__address {
        @media screen and (min-width: 512px) {
          left: 4.5%;
          top: 4.5%;
        }
      }

      &.mapleft__map__address {
        @media screen and (min-width: 512px) {
          left: 50%;
          transform: translateX(-245px);
        }
        @include media(sm){
          transform: translateX(-310px);
        }
        @include media(md){
          transform: translateX(-370px);
        }
        @include media(lg){
          transform: translateX(-235px);
        }
        @include media(xl){
          transform: translateX(-245px);
        }
        @include media(2xl){
          transform: translateX(-350px);
        }

        @include media(3xl){
          transform: translateX(-300px);
        }
      }

      &.maptop__map__address {
        @media screen and (min-width: 512px) {
          left: 50%;
          transform: translateX(-245px);
        }
        @include media(sm){
          transform: translateX(-310px);
        }
        @include media(md){
          transform: translateX(-370px);
        }
        @include media(lg){
          transform: translateX(-490px);
        }
        @include media(xl){
          transform: translateX(-610px);
        }
        @include media(2xl){
          transform: translateX(-660px);
        }
      }

      .contactus__map__address__dir {
        margin-top: 8px;
        max-width: 230px;
      }

      .contactus__map__address__email {
        margin-top: 8px;
        color: #3972e5;
        word-break: break-all;
      }

      div {
        align-items: baseline;
        column-gap: 10px;
        display: flex;
        flex-direction: row;

        i {
          display: flex;
          font-size: 21px;
          justify-content: center;
          min-width: 27px;
          position: relative;
          top: 3px;
        }

        span {
          color: $thanku-thead;
          display: flex;
          flex-direction: column;

          a > p {
            color: $thanku-thead !important;
            font-weight: normal !important;
          }

          p:first-child {
            color: $account-photo-username;
            font-weight: 600;
          }
        }
      }
    }

    &__maps {
      width: 100%;
      height: 560px !important;

      @include media(sm){
        height: 440px !important;
      }

      &_left {
        @include media(lg){
          height: 100% !important;
        }
      }

      &_default {
        @include media(sm){
          border-radius: $border-radius;
        }
      }
    }
  }
}

.icon_row_section {
  font-style: normal !important;
  
  &--extended {
    border: 0 !important;
    margin: 0 !important;
  }

  &--form {
    border-top: 0.5px solid $gray-50;
    margin: 0 30px 30px;
    order: 2 !important;
  }

  .icon_row {
    border-bottom: 1px solid $gray-50;
    padding-bottom: 32px;

    @include media(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0 auto;
      max-width: 1016px;
    }

    &--form {
      display: flex;
      flex-direction: column;
      max-width: 255px;
      row-gap: 16px;
      margin: 0 auto;
      width: 100%;
      border-bottom: 0;
      padding-bottom: 0;
      padding-top: 28px;
    }

     &--compact {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      border-top: 0 !important;
      max-width: 428px;
      font-style: normal !important;
      padding-top: 0;
      gap: 16px;

      @include media(sm) {
        grid-template-columns: 1fr;
      }

      .icon_row__networks__items--extended {
        margin-top: 0;
        justify-content: flex-start;
      }
    }

    &--icons {
      @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
      }
    }


    &__content {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 242px;
      text-align: center;

      &--full {
        max-width: none;
        margin: 0;
        text-align: left !important;
      }

      &--form-centered {
        max-width: 85%;
        order: 2;
      }

      &__icon {
        align-self: center;
        height: 32px;
        position: relative;
        width: 32px;
      }

      .heading {
        font-size: $text-xl;
        letter-spacing: -0.4px;
        line-height: 28px;
        margin: 16px auto 0 auto;

        &--full {
          margin: 0;
          font-size: $text-base;
          line-height: 24px;
          margin-bottom: 4px;
          letter-spacing: -0.02px;
        }
      }

      .link {
        font-size: $text-base;
        letter-spacing: -0.32px;
        line-height: 40px;
        margin: 0 auto;
        max-width: 200px;

        &--full {
          max-width: 400px;
          font-size: $text-sm;
          margin: 0;
          letter-spacing: -0.32px;
          line-height: 20px;
         
        }
      }
    }

    &__description {
      margin: 0 auto;
      max-width: 545px;
      padding-top: 32px;
      text-align: center;

      &--full {
        margin: 0;
        text-align: left;
      }

      &--small {
        padding-top: 0;
      }
    }

    &__networks {
      display: flex;
      justify-content: center;
      margin: 20px auto 10px;

      &--compact {
        margin: 0;
      }

      &--flex {
        display: flex;
        flex-direction: column;
        margin: 0;
      }

      &__items {
        column-gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        width: 100%;

        &--extended {
          margin-top: 12px;
        }

        figure {
          position: relative;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}