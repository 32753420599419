.full_width {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important;
}

.embedded_content {
  & > * {
    margin: 0 auto;
    width: 100%;
  }
}
